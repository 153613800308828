@import 'colors';
@import 'sections/sm-partnership-banner';

.bf-heading {
  font-size: 40px !important;
  line-height: 40px !important;

  &-percent {
    font-size: 100px;
    line-height: 100px;

    @media (min-width: 768px) {
      font-size: 120px;
      line-height: 120px;
    }
  }
}

.promo-ribbon-section {
  position: fixed;
  top: 0;
  z-index: 201;
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  font-size: 12px;
  font-weight: 400;
  background: linear-gradient(95deg, #0b1450 32.66%, #026a9d 104.92%);
  color: #fff;

  @media (min-width: 768px) {
    font-size: inherit;
  }

  @media (min-width: 1024px) {
    display: flex;
  }

  &__arrows {
    position: relative;
    left: -50px;
  }

  &__content {
    flex-wrap: nowrap;
    padding: 0 10px;

    .promo-ribbon-text {
      margin: 0;
    }

    .discount-banner-btn {
      margin-left: 20px;
      background: #22c076;
      color: #fff;
      padding: 8px 20px;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      line-height: 1.15;
      cursor: pointer;
      text-decoration: none;
      max-width: 145px;
      border: 1px solid #25e075;

      &:hover {
        background: #fff;
        border: 1px solid #25e075;
        color: #22c076;
      }

      &:not(:disabled):hover {
        &::after {
          background-color: #22c076;
        }
      }

      &:focus {
        box-shadow: 0 0 15px -2px #22c076;
      }

      &:active {
        color: #22c076;

        &::after {
          background-color: #22c076;
        }
      }
    }
  }

  .promo-ribbon-terms {
    font-weight: 300;
    font-size: 14px;
    cursor: pointer;
  }

  & ~ .htt-header {
    @media (min-width: 1024px) {
      top: 48px;
    }
  }

  .htt-countdown {
    display: inline-grid;
    grid-gap: 6px;
    margin-left: 10px;
    margin-right: 10px;

    &-block {
      padding: 6px 8px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #123165;
      color: #fff;
      font-size: inherit;
      line-height: 1;

      &::before {
        font-family: var(--heading-font);
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
      }

      &__unit {
        margin-top: 0;
        margin-left: auto;
        margin-right: auto;
        font-size: 12px;
        display: block;
      }
    }
  }

  /* Trading Competition ribbon */
  &.-contest-ribbon {
    background: $dark-text-color;

    .htt-countdown-block {
      flex-direction: row;
      margin-top: 0;
      background-color: #415570;

      &::before {
        font-size: 14px;
      }

      &__unit {
        margin-left: 4px;
        margin-right: 0;
      }
    }

    .htt-btn {
      padding: 8px 12px;
      width: fit-content;
      font-size: 14px;
      text-transform: capitalize;
      letter-spacing: 0;
      margin-left: 8px;

      &.btn-mask-icon::after {
        width: 12px;
        height: 12px;
        background-color: #fff;
        margin-left: 4px;
      }
    }

    .btn-caption {
      padding-right: 0;
    }

    span {
      font-style: 14px;

      &:not(.btn-caption) {
        margin-right: 4px;
        font-weight: 500;
      }
    }
  }

  .-contest-ribbon__participants {
    &.htt-countdown-block {
      font-size: 14px;
      color: white;
      font-weight: 400;
    }
  }

  /* SM Top Banner */
  &.sm-partnership-banner {
    .d-flex > div {
      width: auto;
    }

    background: linear-gradient(
      90deg,
      #040c48 -6%,
      #04d189 -16%,
      #00589c 16%,
      #05104d 100%
    );
    gap: 8px;

    small {
      font-size: 12px;
    }

    .sm-btn {
      background: #04d189;
      padding: 8px 16px;
      font-size: 14px;
      font-weight: 600;
      text-transform: none;
      line-height: 1.15;
      cursor: pointer;
      text-decoration: none;
      border: 1px solid #25e075;
      border-radius: 4px;
      gap: 4px;
      color: $sm-dark;
      white-space: nowrap;
      transition: all 0.25s ease-in-out;

      &::after {
        width: 14px;
        height: 14px;
        background-color: $sm-dark;
      }

      &:hover {
        background-color: $sm-green-light;

        &::after {
          background-color: $sm-dark;
        }
      }
    }

    .sm-partnership-banner {
      &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1240px;
        padding: 0 16px;

        .promo-ribbon-text {
          white-space: nowrap;
        }
      }

      &__img {
        -webkit-clip-path: polygon(20% 0, 100% 0, 82% 100%, 0% 100%);
        clip-path: polygon(20% 0, 100% 0, 82% 100%, 0% 100%);
        height: 48px;
        width: 145px;
        object-position: 0 -2px;
      }

      &__content {
        padding: 0;

        &__price {
          display: inline;
          margin-left: 8px;
          color: $sm-green;
          font-size: 18px;

          @media (min-width: 1120px) {
            font-size: 22px;
          }
        }

        &__subtitle {
          display: inline;
          font-style: italic;
          font-weight: 500;
          font-size: 14px;

          @media (min-width: 1120px) {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.promo-ribbon-mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 201;
  width: 100%;

  @media (max-width: 1023px) {
    .promo-mobile-trigger {
      background: linear-gradient(95deg, #0b1450 32.66%, #026a9d 104.92%);
      box-shadow: 0px -6px 22px rgba(0, 62, 137, 0.3);
      padding: 16px 0;
      font-weight: 400;
      transition: 0.3s;
      color: #fff;
      height: 75px;

      &__icon {
        transform: rotate(180deg);
        margin: 0 auto 12px;
        display: block;
        transition: 0.3s;
      }

      &__text {
        color: #fff;
        display: block;
        margin: 0 auto;
        text-align: center;
        opacity: 1;
        visibility: visible;
        transition: 0.3s;
      }

      &.active {
        padding-bottom: 0;

        .promo-mobile-trigger__icon {
          transform: rotate(0deg);
        }

        .promo-mobile-trigger__text {
          opacity: 0;
          visibility: hidden;
        }
      }
    }

    .promo-mobile-content {
      background: linear-gradient(95deg, #0b1450 32.66%, #026a9d 104.92%);
      max-height: 0;
      height: 100%;
      overflow: hidden;
      transition: 0.3s;
      transition-delay: 0.3s;
      color: #fff;

      &.active {
        max-height: 400px;
        padding: 0 16px 16px;
      }

      .promo-ribbon-text {
        &__heading {
          font-family: var(--heading-font);
          font-size: 30px;
          line-height: 35px;
          font-weight: 700;
          text-align: center;
        }

        &__content {
          font-size: 16px;
          line-height: 21px;
          text-align: center;
        }
      }

      .discount-banner-btn {
        margin: 16px auto 0;
        background: #22c076;
        color: #fff;
        padding: 16px 20px;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        line-height: 1.15;
        cursor: pointer;
        text-decoration: none;
        max-width: 200px;
        width: 100%;
        display: block;
        text-align: center;
        border: 1px solid #25e075;

        &:hover {
          background: #fff;
          border: 1px solid #25e075;
          color: #22c076;
        }

        &:not(:disabled):hover {
          &::after {
            background-color: #22c076;
          }
        }

        &:focus {
          box-shadow: 0 0 15px -2px #22c076;
        }

        &:active {
          color: #22c076;

          &::after {
            background-color: #22c076;
          }
        }
      }

      .htt-countdown {
        display: flex;
        gap: 16px;
        justify-content: center;
        margin: 0 40px;

        &-block {
          flex: 1 1 100%;
        }
      }

      .htt-btn {
        max-width: 100%;
      }
    }
  }

  @media (min-width: 1024px) {
    display: none;
  }
}

@media (max-width: 1023px) {
  .promo-ribbon-mobile.-competition-ribbon {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.09);
    color: $dark-text-color;

    .promo-mobile-trigger,
    .promo-mobile-content {
      background: $lightest-blue;
      color: $dark-text-color;

      span:not(.promo-mobile-trigger__text) {
        display: inline;
        line-height: 2;
      }
    }

    .promo-mobile-trigger__icon,
    .promo-mobile-trigger__text {
      color: $dark-text-color;
    }

    .htt-countdown-block {
      background: #d1e3ff;
      color: $dark-text-color;
    }

    .-inline-countdown {
      .htt-countdown-block {
        flex-direction: row;
        color: $dark-text-color;
        padding: 4px 5px;

        &::before {
          font-size: 13px;
          margin-right: 4px;
        }
      }
    }

    .promo-mobile-content {
      &.active {
        padding: 4px 16px 12px;
      }

      .htt-countdown {
        display: flex;
        gap: 8px;
        justify-content: flex-end;
        margin: 8px 0 0;
      }

      .htt-btn {
        margin-top: 12px;

        &::after {
          width: 14px;
          height: 14px;
        }
      }
    }

    .promo-mobile-trigger {
      .promo-mobile-trigger__text {
        font-size: 18px;
        font-weight: 500;
      }

      &.active {
        .promo-mobile-trigger__text {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  .-contest-ribbon__participants.htt-countdown-block {
    display: inline;
    padding: 4px;
  }

  .promo-ribbon-mobile {
    &.-sm-ad-ribbon {
      background: linear-gradient(
        327.43deg,
        #040c48 12.89%,
        #00589c 66.07%,
        #04d189 103.94%
      );
    }

    .promo-mobile-content,
    .promo-mobile-trigger {
      background: transparent;
    }
  }
}
