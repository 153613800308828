/* stylelint-disable selector-max-type */

// variables
$color-onyx: #0f0f0f;
$color-white: #fff;
$color-htt-blue: #2c7bfd;
$color-htt-blue-dark: #21509e;
$color-htt-back-blue: #eaf2ff;
$text-color: #fff;
$dark-text-color: #2c3d55;
$light-grey-text-color: #656569;
$link-color: $color-htt-blue;
$bg-color: #fff;
$dark-bg-color: $color-onyx;
$btn-hover-bg: #eef4ff;
$btn-active-bg: #fff;
$link-mask-color: $color-htt-blue;
$link-mask-hover-color: $color-htt-blue;
$plyr-color-main: $color-htt-blue;
$error-color: #ff4848;
$success-color: #155724;
$dark-fg-color: #1c1c1e;
$dark-grey-border-color: #434345;
$light-grey-border-color: #cfcfcf;
$faded-text-color-dark: #3f3f41;
$faded-text-color-light: #737373;

// New design colours
$light-blue: #ebf2fe;
$lightest-blue: #f5f8ff;
$grey-blue: #cddaf0;
$dark-blue: #1c2736;

//SM colors
$sm-green: #04d189;
$sm-green-light: #00f198;
$sm-dark: #212b36;

// themes
:root,
html[data-theme='blue'],
html[data-theme='green'] {
  --accent-color: #{$color-htt-blue};
  //--text-color: #fff;
  //--dark-text-color: #{$color-onyx};
  //--link-color: #{$color-htt-blue};
  //--bg-color: #fff;
  //--dark-bg-color: #{$color-onyx};
  //--btn-hover-bg: #eef4ff;
  //--btn-active-bg: #fff;
  //--link-mask-color: #{$color-htt-blue};
  //--link-mask-hover-color: #{$color-htt-blue};
  //--plyr-color-main: #{$color-htt-blue};
  //--error-color: #ff4848;
  //--dark-fg-color: #1c1c1e;
  //--dark-grey-border-color: #434345;
  //--light-grey-border-color: #cfcfcf;
}

html[data-theme='green'] {
  --accent-color: #25e075;
}

.black-on-white {
  color: $dark-text-color;
}

.white-on-black {
  color: $text-color;

  .htt-btn {
    &.btn-outline {
      background-color: $dark-bg-color;
      color: $text-color;
      border-color: $text-color;

      &:not(:disabled):hover {
        color: $text-color;
        background-color: #56595d;
      }

      &.btn-mask-icon {
        &::after {
          background-color: $text-color;
        }
      }
    }
  }
}

.dark-bg {
  background: $dark-bg-color;
}

.white-bg {
  background: $bg-color;
}

.lightest-blue-bg {
  background: $lightest-blue;
}

.light-blue-bg {
  background: $light-blue;
}

.dark-blue-bg {
  background: $dark-blue;
}

$colors-panels-list: $color-htt-blue, #2bcc7a, #f79b22, #7d5cff, #eb5750;
