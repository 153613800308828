.sm-partnership-banner-section {
  display: none;

  @media (min-width: 1024px) {
    display: block;
    padding-top: var(--small-section-gap);
    padding-bottom: var(--small-section-gap);
  }

  .sm-partnership-banner {
    border-radius: var(--border-radius);
    background: linear-gradient(
      90.01deg,
      #040c48 5.35%,
      #04d189 30%,
      #00589c 51%,
      #05104d 100%
    );
    display: flex;
    justify-content: space-around;
    gap: 5px;
    width: 100%;
    height: auto;
    max-height: 140px;
    overflow: hidden;
    padding: 0 20px;

    &__img {
      clip-path: polygon(20% 0, 100% 0, 82% 100%, 0% 100%);
      height: 140px;
      margin-right: 16px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-height: 140px;
        max-width: 290px;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      flex: 1;
      margin-right: 32px;

      &__price {
        color: $sm-green;
        font-size: 26px;

        @media (min-width: 1040px) {
          font-size: 28px;

          span {
            display: block;
          }
        }
      }

      &__subtitle {
        font-style: italic;
        font-size: 18px;

        @media (min-width: 1165px) {
          font-size: 20px;
        }
      }

      .free-to-enter {
        align-self: center;
      }

      .htt-countdown {
        width: 100%;
        margin: 12px 0;

        .htt-countdown-block {
          background-color: #415570;
          color: #fff;

          &::before {
            color: #fff;
            font-size: 14px;
          }
        }
      }

      .logo {
        display: flex;
        align-items: center;
      }
    }
  }

  &.--small {
    .htt-container {
      width: 100%;
      padding: 0;
    }

    .sm-partnership-banner {
      max-height: 82px;

      &__img {
        width: 280px;

        img {
          max-height: 120px;
        }
      }

      &__sm-offer {
        span {
          font-size: 12px;
        }

        img {
          width: 100px;
          height: auto;
        }
      }

      &__content {
        &__subtitle {
          font-size: 20px;
        }

        &__price {
          font-size: 22px;
          white-space: nowrap;

          span {
            display: inline;
          }
        }
      }
    }
  }
}

.sm-partnership-banner-section,
.-sm-ad-ribbon {
  .sm-btn {
    position: relative;
    padding: 14px 20px;
    user-select: none;
    text-decoration: none;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.15;
    white-space: nowrap;
    transition-property: background-color, box-shadow;
    transition-duration: 0.25s;
    transition-timing-function: linear;
    max-width: 100%;
    border-radius: var(--border-radius);
    color: $sm-dark;
    background: $sm-green;
    border: 1px solid $sm-green;
    width: fit-content;

    &::after {
      width: 16px;
      height: 16px;
      background-color: $sm-dark;
    }

    &:hover {
      background: $sm-green-light;

      &::after {
        background-color: $sm-dark;
      }
    }
  }

  .trustpilot-widget {
    transform: scale(0.8);
    margin-top: 14px;
    margin-bottom: -4px;
  }
}

.-sm-ad-ribbon {
  &.promo-ribbon-mobile {
    display: none;

    @media (max-width: 1023px) {
      &.visible {
        display: block;
      }
    }

    .promo-mobile-content {
      position: relative;
      max-height: 0;
      transition: max-height 0.5s ease-in-out;

      & > .position-relative > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transform: scaleX(-1);
        object-position: 60%;
      }

      &.active {
        padding: 0;
        max-height: calc(100vh - 114px);
        overflow: auto;
      }

      &__close-button {
        position: absolute;
        top: 0;
        right: 0;
        margin-right: 12px;
        margin-top: 24px;
        background: $dark-bg-color;
        border: 1px solid rgb(255 255 255 / 50%);
        line-height: 0;
        padding: 4px;
        border-radius: 100px;
        cursor: pointer;
        z-index: 2;

        @media (min-width: 600px) and (max-width: 1023px) {
          left: auto;
          right: 0;
          margin-left: 12px;
        }
      }

      &__wrapper {
        padding: 16px 16px 0;

        @media (min-width: 600px) {
          position: relative;
        }
      }

      &__subtitle {
        font-size: 18px;
        font-style: italic;
        margin-top: 12px;
        display: block;
      }

      &__title {
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 8px;
        color: $sm-green;
      }

      &__list {
        li {
          font-size: 14px;
          margin-bottom: 8px;
          display: flex;
          align-items: center;
          gap: 6px;

          svg {
            color: $sm-green;
          }
        }
      }

      @media (min-width: 600px) and (max-width: 1023px) {
        display: grid;
        grid-template-columns: 1fr 1.5fr;
        border-bottom: 1px solid rgb(4 209 137 / 30%);

        &.active {
          overflow: hidden;
        }

        .sm-partnership-banner__img {
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          &.-flip-img {
            img {
              transform: scaleX(-1);
              object-position: 80%;
            }
          }
        }
      }
    }

    .promo-mobile-trigger {
      padding: 16px;
      max-height: 600px;
      height: auto;
      box-shadow: none;
      transition: all 0.25s ease-in-out;

      .sm-btn {
        width: 100%;
        max-width: 400px;
        margin: auto;
      }

      &__text {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 12px;
        margin-bottom: 12px;
        font-weight: 500;
      }

      &__icon {
        transform: rotate(0);
        margin: 0 0 0 8px;
      }

      .promo-ribbon-mobile {
        &__hide-ad {
          margin-top: 12px;
          text-align: center;
          text-decoration: underline;
          color: #818b9a;
        }

        &__trustpilot {
          display: none;

          svg {
            margin-left: 0;
            width: 170px;
            margin-top: 16px;
          }
        }
      }

      &.active {
        padding-bottom: 18px;

        @media (max-height: 567px) {
          border-top: 1px solid rgb(4 209 137 / 20%);
        }

        .promo-mobile-trigger__text,
        .promo-mobile-trigger__sm-logo,
        .promo-ribbon-mobile__hide-ad {
          visibility: hidden;
          max-height: 0;
          padding: 0;
          margin: 0;
          overflow: hidden;
        }

        .promo-ribbon-mobile__trustpilot {
          display: flex;
        }
      }
    }
  }
}
